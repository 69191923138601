import { PaginationData, request } from '@/util/request';
import { message } from 'ant-design-vue';

export interface GiftCoupon {
    create_time?: number;
    creator_name?: string;
    end_time?: number;
    exchange_number?: number;
    gen_number?: number;
    id?: number;
    prefix?: string;
    product_id?: number;
    product_price?: number;
    product_title?: string;
    release_number?: number;
    start_time?: number;
    title?: string;
    update_time?: number;
}
export interface GiftCouponCode {
    code: string;
    create_time: number;
    end_time: number;
    exchange_time: number;
    exchange_type: number;
    express_address: string;
    express_mobile: string;
    express_name: string;
    giftcoupon_id: number;
    id: number;
    mobile: string;
    nickname: string;
    order_id: number;
    passwd: string;
    register_id: number;
    start_time: number;
    status: number;
    update_time: number;
}
export interface CreateGiftCoupon {
    end_time: number;
    prefix: string;
    product_id: number;
    release_number: number;
    start_time: number;
    title: string;
}


export async function createGiftCoupon(params) {
    const url = `/boss/marketing/giftcoupon`;
    const res = await request.post<PaginationData<CreateGiftCoupon>>(url, {
        ...params
    });
    if (res.status == 200) {
        message.success('创建成功');
        return true;
    } else {
        message.warning('创建失败' + res.message);
        return false;
    }
}
export async function getGiftCoupon(params: any) {
    const url = `/boss/marketing/giftcoupon`;
    const res = await request.get<PaginationData<GiftCoupon>>(url, {
        params,
    });
    if (res.status == 200 && res.data) {
        return res.data;
    } else {
        return {
            current: 1,
            detail: [],
            last: 0,
            limit: 1,
            total: 1,
        };
    }
}
export async function createGiftCouponCode(giftcoupon_id: number, number: number) {
    const url = `/boss/marketing/giftcoupon/code`;
    const res = await request.post<PaginationData<GiftCoupon>>(url, {
        giftcoupon_id,
        number,
    });
    if (res.status == 200) {
        message.success('创建成功');
        return true;
    } else {
        message.warning('创建失败' + res.message);
        return false;
    }
}
export async function getGiftCouponCode(params: any) {
    const url = `/boss/marketing/giftcoupon/code`;
    const res = await request.get<PaginationData<GiftCouponCode>>(url, {
        params,
    });
    if (res.status == 200 && res.data) {
        return res.data;
    } else {
        return {
            current: 1,
            detail: [],
            last: 0,
            limit: 1,
            total: 1,
        };
    }
}
export interface ExchangeParams {
    /**明码 */
    giftcoupon_code: string;
    /**密码 */
    giftcoupon_passwd: string;
    /** 详细地址*/
    address: string;
    /**市*/
    city_name: string;
    /** 区*/
    area_name: string;
    /** 客户收货人*/
    express_name: string;
    /** 客户手机号*/
    mobile: string;
    /** 省*/
    province_name: string;
}
export async function exchangeGiftCouponCode(data: ExchangeParams) {
    const url = `/boss/marketing/giftcoupon/code/exchange`;
    const res = await request.post(url, data);
    if (res.status == 200 && res.data) {
        message.success('兑换成功')
        return true

    } else {
        message.warning('兑换失败' + res.message);
        return false
    }
}