import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useUser } from '@/pinia/modules/userData';

/**
 * 获取当前所在页面的权限
 * ['编辑','查看','导出']
 * @returns 
 */
export function getPermission(_){
//获取当前页面的menu数据
  const { thisMenu } = storeToRefs(useUser());
  const permission = computed(() => {
    //如果有当前页面的数据，并且当前页面一定是子菜单，那么他的child一定是《权限》，获取其name返回即可
    if (thisMenu.value.length > 0 && thisMenu.value[0].is_menu == 1) {
      return thisMenu.value[0].child.map(({ name }) => name ).filter(Boolean);
    }
    return [];
  });
  return permission;
}
